import * as React from 'react';
import { Box, CssBaseline, AppBar, Toolbar, Typography, Drawer, IconButton, Container, Menu, Tooltip, MenuItem, Avatar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar'; // Pastikan path ini benar untuk komponen Sidebar
import Logo from './indibiz-logo.png'; // Ganti dengan path logo yang sesuai

const drawerWidth = 240;
const settings = ['Profile', 'Logout'];

const Layout = ({ children }) => {
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: '100vw',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: '#fff',
                    borderBottom: '1px solid #e0e0e0',
                }}
            >
                <Container maxWidth="xl" disableGutters>
                    <Toolbar disableGutters sx={{ padding: '0 20px' }}>
                    <IconButton
    color="inherit"
    aria-label="open drawer"
    onClick={handleDrawerToggle}
    edge="start"
    sx={{
        mr: 2,
        display: { sm: 'none' },
        color: 'black', // Change the color to black
    }}
>
    <MenuIcon />
</IconButton>

                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.1rem',
                                color: 'black',
                                textDecoration: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate('/')}
                        >
                            ULP
                            <Box
                                component="img"
                                src={Logo}
                                alt="Indibiz Logo"
                                sx={{ width: 100, marginLeft: 1 }} // Perkecil ukuran logo
                            />
                        </Typography>

                        {/* User Profile */}
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="User Avatar" src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={setting === 'Logout' ? handleLogout : handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            {/* Sidebar */}
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { width: drawerWidth },
                }}
            >
                <Sidebar />
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { width: drawerWidth },
                }}
            >
                <Sidebar />
            </Drawer>

            {/* Main Content */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    p: 3,
                    mt: 8, // Adjust for AppBar height
                    ml: { sm: `${drawerWidth}px` }, // Margin left to adjust for sidebar
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default Layout;
