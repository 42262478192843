import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
    Typography, Box, Card, CardContent, Grid, 
    Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Paper, IconButton, Alert, Tooltip, Modal 
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Assignment as TotalOrderIcon, CheckCircle as CompletedOrderIcon, Stars as PointsIcon } from '@mui/icons-material';
import { useTheme, useMediaQuery } from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const Dashboard = () => {
    const [totalOrders, setTotalOrders] = useState(0);
    const [completedOrders, setCompletedOrders] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const [latestOrders, setLatestOrders] = useState([]);
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const fetchDashboardData = async () => {
        try {
            const token = localStorage.getItem('token');
            const [ordersRes, pointsRes] = await Promise.all([
                axios.get(`${apiUrl}/orders`, {
                    headers: { Authorization: token },
                    params: { limit: 1000 },
                }),
                axios.get(`${apiUrl}/points`, {
                    headers: { Authorization: token },
                }),
            ]);

            const orders = ordersRes.data.data || [];
            setTotalOrders(orders.length);
            setCompletedOrders(orders.filter(order => order.status === 'Completed').length);
            setLatestOrders(orders.slice(0, 10));
            setTotalPoints(pointsRes.data.points || 0);
        } catch (err) {
            console.error('Error fetching dashboard data:', err);
        }
    };

    useEffect(() => {
        fetchDashboardData();
    }, []);

    const handleRowClick = (order) => {
        setSelectedOrder(order);
    };

    const handleCloseModal = () => {
        setSelectedOrder(null);
    };

    return (
        <Box sx={{ p: { xs: 2, md: 3 } }}>
           

            {showWelcomeMessage && (
                <Alert 
                    severity="info"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => setShowWelcomeMessage(false)}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 3 }}
                >
                    Selamat Datang di Unorganic Lead Platform IndiBiz Witel BeKar. Silahkan masuk ke menu Tanya Produk untuk menghubungi AI Assistant IndiBiz BeKar, masuk ke menu "Order" untuk input baru order IndiBiz, masuk ke menu "Points" untuk redeem Point Lead.
                </Alert>
            )}

            {/* Summary Cards */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={4}>
                    <Tooltip title="Total Orders">
                        <Card>
                            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                <TotalOrderIcon sx={{ fontSize: { xs: 30, md: 40 }, mr: 2 }} color="primary" />
                                <Box>
                                    <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}>
                                        Leads
                                    </Typography>
                                    <Typography variant="h5" sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}>
                                        {totalOrders}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Tooltip title="Completed Orders">
                        <Card>
                            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                <CompletedOrderIcon sx={{ fontSize: { xs: 30, md: 40 }, mr: 2 }} color="success" />
                                <Box>
                                    <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}>
                                        Completed
                                    </Typography>
                                    <Typography variant="h5" sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}>
                                        {completedOrders}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Tooltip title="Total Points">
                        <Card>
                            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                                <PointsIcon sx={{ fontSize: { xs: 30, md: 40 }, mr: 2 }} color="secondary" />
                                <Box>
                                    <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}>
                                        Points
                                    </Typography>
                                    <Typography variant="h5" sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}>
                                        {totalPoints}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Tooltip>
                </Grid>
            </Grid>

            {/* Latest Orders Table */}
            <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' } }}>
                Last Leads
            </Typography>
            <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontSize: { xs: '0.75rem', md: '1rem' } }}>Business Name</TableCell>
                            <TableCell sx={{ fontSize: { xs: '0.75rem', md: '1rem' } }}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {latestOrders.map(order => (
                            <TableRow 
                                key={order.id} 
                                onClick={() => handleRowClick(order)} 
                                sx={{ cursor: 'pointer' }}
                            >
                                <TableCell sx={{ fontSize: { xs: '0.75rem', md: '1rem' } }}>
                                    {order.business_name}
                                </TableCell>
                                <TableCell sx={{ fontSize: { xs: '0.75rem', md: '1rem' } }}>
                                    {order.status}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal for Order Details */}
            <Modal open={!!selectedOrder} onClose={handleCloseModal}>
                <Box sx={{
                    position: 'absolute', 
                    top: '50%', 
                    left: '50%', 
                    transform: 'translate(-50%, -50%)', 
                    bgcolor: 'background.paper', 
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 3,
                    width: { xs: '90%', md: 400 }
                }}>
                    {selectedOrder && (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Leads Details
                            </Typography>
                            <Typography variant="body2">PIC: {selectedOrder.pic}</Typography>
                            <Typography variant="body2">Business Name: {selectedOrder.business_name}</Typography>
                            <Typography variant="body2">Email: {selectedOrder.email}</Typography>
                            <Typography variant="body2">Phone Number: {selectedOrder.phone_number}</Typography>
                            <Typography variant="body2">Address: {selectedOrder.address}</Typography>
                            <Typography variant="body2">Package: {selectedOrder.package_name || 'N/A'}</Typography>
                            <Typography variant="body2">Status: {selectedOrder.status}</Typography>
                        </>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default Dashboard;
