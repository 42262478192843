import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Snackbar, Alert, CircularProgress } from '@mui/material';
import axios from 'axios';

const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, { token, password });
            setSuccessMessage('Password reset successfully! You can now login.');
            setPassword('');
            setErrorMessage('');
        } catch (error) {
            console.error(error);
            setErrorMessage('Failed to reset password. The token might have expired.');
            setSuccessMessage('');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f9f9f9',
                padding: 3,
            }}
        >
            <Box
                sx={{
                    maxWidth: 400,
                    width: '100%',
                    backgroundColor: '#fff',
                    padding: 4,
                    borderRadius: 2,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
                    Reset Password
                </Typography>
                <Typography variant="body1" sx={{ color: 'gray', mb: 3 }}>
                    Enter your new password below.
                </Typography>

                {/* Alert Messages */}
                {successMessage && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        {successMessage}
                    </Alert>
                )}
                {errorMessage && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {errorMessage}
                    </Alert>
                )}

                {/* Password Input */}
                <TextField
                    fullWidth
                    label="New Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    sx={{ mb: 2 }}
                />

                {/* Submit Button */}
                <Button
                    variant="contained"
                    fullWidth
                    disabled={isLoading}
                    sx={{
                        backgroundColor: isLoading ? '#007bff80' : '#007bff',
                        '&:hover': { backgroundColor: isLoading ? '#007bff80' : '#0056b3' },
                        mb: 2,
                    }}
                    onClick={handleSubmit}
                    startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                >
                    {isLoading ? 'Processing...' : 'Reset Password'}
                </Button>

                {/* Login Button */}
                <Button
                    variant="text"
                    fullWidth
                    onClick={() => navigate('/login')}
                    sx={{
                        color: '#007bff',
                        textTransform: 'none',
                        mt: 1,
                    }}
                >
                    Back to Login
                </Button>

                
            </Box>
        </Box>
    );
};

export default ResetPassword;
