import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TextField
} from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL; // Use environment variable

const TableMenu = () => {
    const [packages, setPackages] = useState([]);
    const [filteredPackages, setFilteredPackages] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Fetch all packages from the backend
    const fetchPackages = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`${apiUrl}/packages`, {
                headers: {
                    Authorization: token,
                }
            });
            setPackages(res.data);
            setFilteredPackages(res.data);
        } catch (err) {
            console.error('Error fetching packages:', err);
        }
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    // Handle search input changes
    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        setPage(0); // Reset to first page when searching

        // Filter packages based on the search query
        const filtered = packages.filter((pkg) =>
            pkg.package_name.toLowerCase().includes(query) ||
            pkg.fee_sales.toString().includes(query)
        );
        setFilteredPackages(filtered);
    };

    // Handle pagination page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Calculate the packages to display on the current page
    const paginatedPackages = filteredPackages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Fee Sales Packages
            </Typography>
            <TextField
                label="Search Packages"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchQuery}
                onChange={handleSearchChange}
            />
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Package Name</TableCell>
                            <TableCell>Fee Sales (Rp)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedPackages.map((pkg) => (
                            <TableRow key={pkg.id}>
                                <TableCell>{pkg.package_name}</TableCell>
                                <TableCell>{pkg.fee_sales}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={filteredPackages.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                sx={{ mt: 2 }}
            />
        </Box>
    );
};

export default TableMenu;
