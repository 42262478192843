import React, { useState } from 'react';
import {
    Box, TextField, Button,IconButton, Alert, Typography, Paper, List, ListItem, ListItemText
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';

const ChatAssistant = () => {
    const [chatHistory, setChatHistory] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);

    const handleSend = async () => {
        if (!input.trim()) return;
        setLoading(true);

        const newChatHistory = [...chatHistory, { sender: 'user', message: input }];

        try {
            const token = localStorage.getItem('token');
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/chat`,
                { prompt: input },
                { headers: { Authorization: `${token}` } }
            );

            const reply = res.data.reply || "Sorry, I couldn't process that.";
            setChatHistory([...newChatHistory, { sender: 'assistant', message: reply }]);
        } catch (error) {
            console.error('Error communicating with AI Assistant:', error);
            setChatHistory([...newChatHistory, { sender: 'assistant', message: 'Something went wrong.' }]);
        } finally {
            setLoading(false);
            setInput('');
        }
    };

    return (
        <Box sx={{ padding: 4, margin: '0 auto' }}>
             {showWelcomeMessage && (
                <Alert 
                    severity="info"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => setShowWelcomeMessage(false)}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 3 }}
                >
                    Halo perkenalkan saya ULPA (Unorganic Lead Platform Assistant) saya adalah Assistant Virtual untuk Platform ini, saya siap membantu anda menjelaskan product B2B yang dimiliki Telkom, dan saya bisa membantu anda melakukan pengecekan lead IndiBiz di Platform ini.
                </Alert>
            )}
            <Paper sx={{ padding: 2, mb: 3, height: 300, overflowY: 'auto' }}>
                <List>
                    {chatHistory.map((chat, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={chat.message}
                                secondary={chat.sender === 'user' ? 'You' : 'AI Assistant'}
                                sx={{
                                    textAlign: chat.sender === 'user' ? 'right' : 'left',
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
            <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Tanya saya seputar produk B2B telkom.."
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') handleSend();
                    }}
                    disabled={loading}
                />
                <Button
                    variant="contained"
                    onClick={handleSend}
                    disabled={loading}
                >
                    Kirim
                </Button>
            </Box>
        </Box>
    );
};

export default ChatAssistant;
