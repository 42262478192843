import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const AdminPoints = () => {
    const [pointsRequests, setPointsRequests] = useState([]);
    const [redeemDetail, setRedeemDetail] = useState(null);
    const [detailDialogOpen, setDetailDialogOpen] = useState(false);
    const [transferProof, setTransferProof] = useState(null);
    const [approveDialogOpen, setApproveDialogOpen] = useState(false);
    const [currentRequestId, setCurrentRequestId] = useState(null);

    const fetchPointsRequests = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`${apiUrl}/admin/points-requests`, {
                headers: {
                    Authorization: token,
                },
            });
            setPointsRequests(res.data);
        } catch (err) {
            console.error('Error fetching points requests:', err);
        }
    };

    useEffect(() => {
        fetchPointsRequests();
    }, []);

    const handleApprove = async () => {
        if (!transferProof) {
            alert("Please upload a transfer proof before approving.");
            return;
        }

        const formData = new FormData();
        formData.append("transferProof", transferProof);

        try {
            const token = localStorage.getItem('token');
            await axios.put(`${apiUrl}/admin/points-request/${currentRequestId}/approve`, formData, {
                headers: {
                    Authorization: token,
                    "Content-Type": "multipart/form-data",
                },
            });
            fetchPointsRequests(); // Refresh list after approval
            setApproveDialogOpen(false);
            setTransferProof(null);
        } catch (err) {
            console.error('Error approving request:', err);
        }
    };

    const handleReject = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${apiUrl}/admin/points-request/${id}/reject`, {}, {
                headers: {
                    Authorization: token,
                },
            });
            fetchPointsRequests(); // Refresh list after rejection
        } catch (err) {
            console.error('Error rejecting request:', err);
        }
    };

    const handleViewDetail = (redeem) => {
        setRedeemDetail(redeem);
        setDetailDialogOpen(true);
    };

    const closeDetailDialog = () => {
        setDetailDialogOpen(false);
        setRedeemDetail(null);
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Points Requests
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User ID</TableCell>
                            <TableCell>User Name</TableCell>
                            <TableCell>Points</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Details</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pointsRequests.map((request) => (
                            <TableRow key={request.id}>
                                <TableCell>{request.user_id}</TableCell>
                                <TableCell>{request.user_name || 'N/A'}</TableCell>
                                <TableCell>{request.points}</TableCell>
                                <TableCell>{request.action}</TableCell>
                                <TableCell>{request.status}</TableCell>
                                <TableCell>{new Date(request.created_at).toLocaleString()}</TableCell>
                                <TableCell>
                                    {request.action === 'redeem' && (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleViewDetail(request)}
                                        >
                                            Lihat Detail
                                        </Button>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {request.status === 'pending' && (
                                        <>
                                            <Button
                                                onClick={() => {
                                                    setApproveDialogOpen(true);
                                                    setCurrentRequestId(request.id);
                                                }}
                                                color="primary"
                                                variant="outlined"
                                                sx={{ mr: 1 }}
                                            >
                                                Approve
                                            </Button>
                                            <Button
                                                onClick={() => handleReject(request.id)}
                                                color="secondary"
                                                variant="outlined"
                                            >
                                                Reject
                                            </Button>
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dialog for Redeem Detail */}
            <Dialog open={detailDialogOpen} onClose={closeDetailDialog}>
                <DialogTitle>Detail Redeem</DialogTitle>
                <DialogContent>
    {redeemDetail && (
        <>
            <DialogContentText>Nama: {redeemDetail.name}</DialogContentText>
            <DialogContentText>Bank: {redeemDetail.bank}</DialogContentText>
            <DialogContentText>No Rekening: {redeemDetail.account_number}</DialogContentText>
            {redeemDetail.transfer_proof ? (
                <DialogContentText>
                    Bukti Transfer: <a
                        href={`${apiUrl}/uploads/${redeemDetail.transfer_proof}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Lihat Bukti Transfer
                    </a>
                </DialogContentText>
            ) : (
                <DialogContentText>Bukti Transfer: Tidak ada</DialogContentText>
            )}
        </>
    )}
</DialogContent>

                <DialogActions>
                    <Button onClick={closeDetailDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for Approving with Transfer Proof */}
            <Dialog open={approveDialogOpen} onClose={() => setApproveDialogOpen(false)}>
                <DialogTitle>Approve Redeem Request</DialogTitle>
                <DialogContent>
                    <DialogContentText>Upload transfer proof to approve this request.</DialogContentText>
                    <input
                        type="file"
                        onChange={(e) => setTransferProof(e.target.files[0])}
                        accept="image/*,application/pdf"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setApproveDialogOpen(false)} color="secondary">Cancel</Button>
                    <Button onClick={handleApprove} color="primary">Approve</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdminPoints;
