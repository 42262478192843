import React, { useEffect } from 'react';

const CekTagihan = () => {
    useEffect(() => {
        // Pastikan file `datatagihan.js` sudah di-load
        const btnPay = document.getElementById('btnpay');
        if (btnPay) {
            btnPay.addEventListener('click', () => {
                // Trigger fungsi di `datatagihan.js`
                console.log('Button pay clicked');
            });
        }
    }, []);

    return (
        <div>
            <select className="form-control chosen" id="caripay">
                <option value="122873222940">122873222940-INTERNET</option>
            </select>
            <span
                className="btn btn-danger btn-sm"
                id="btnpay"
                style={{ borderWidth: '2.3px' }}
            >
                <i className="icon-search"></i> Cari
            </span>
        </div>
    );
};

export default CekTagihan;
