import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Select, MenuItem, InputLabel, FormControl, Button } from '@mui/material';

const AdminUserSummary = () => {
    const [userSummary, setUserSummary] = useState([]);
    const [categorySummary, setCategorySummary] = useState([]);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState(new Date().getFullYear());

    const fetchUserSummary = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/user-summary`, {
                headers: { Authorization: token },
                params: { month, year },
            });
            setUserSummary(response.data);
        } catch (err) {
            console.error('Error fetching user summary:', err);
        }
    };

    const fetchCategorySummary = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/category-summary`, {
                headers: { Authorization: token },
            });
            setCategorySummary(response.data);
        } catch (err) {
            console.error('Error fetching category summary:', err);
        }
    };

    useEffect(() => {
        fetchUserSummary();
        fetchCategorySummary();
    }, [month, year]);

    return (
        <Box sx={{ p: 3 }}>
           
            {/* Filter Controls */}
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                <FormControl>
                    <InputLabel>Month</InputLabel>
                    <Select value={month} onChange={(e) => setMonth(e.target.value)}>
                        <MenuItem value=""><em>All</em></MenuItem>
                        {[...Array(12)].map((_, i) => (
                            <MenuItem key={i + 1} value={i + 1}>{new Date(0, i).toLocaleString('default', { month: 'long' })}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>Year</InputLabel>
                    <Select value={year} onChange={(e) => setYear(e.target.value)}>
                        {[2022, 2023, 2024].map((yr) => (
                            <MenuItem key={yr} value={yr}>{yr}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" onClick={fetchUserSummary}>Filter</Button>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
             {/* Category Summary Table */}
             <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Category</TableCell>
                            <TableCell>Total Orders</TableCell>
                            <TableCell>Completed Orders</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categorySummary.map(category => (
                            <TableRow key={category.category}>
                                <TableCell>{category.category}</TableCell>
                                <TableCell>{category.total_orders}</TableCell>
                                <TableCell>{category.completed_orders}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
</Box>
            {/* User Summary Table */}
            <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Total Orders</TableCell>
                            <TableCell>Completed Orders</TableCell>
                            <TableCell>Total Points</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userSummary.map(user => (
                            <TableRow key={user.id}>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.category}</TableCell>
                                <TableCell>{user.total_orders}</TableCell>
                                <TableCell>{user.completed_orders}</TableCell>
                                <TableCell>{user.points}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

           
        </Box>
    );
};

export default AdminUserSummary;
