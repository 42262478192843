import React, { useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Alert,
    CircularProgress,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false); // State untuk loading
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setIsLoading(true); // Set tombol ke loading saat proses mulai
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, { email });
            setSuccessMessage('A reset password email has been sent to your email address.');
            setErrorMessage(''); // Clear error message
            setEmail('');
        } catch (error) {
            console.error(error);
            setSuccessMessage(''); // Clear success message
            setErrorMessage('Failed to send reset email. Please check your email address.');
        } finally {
            setIsLoading(false); // Set loading ke false setelah proses selesai
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f9f9f9',
                padding: 3,
            }}
        >
            <Box
                sx={{
                    maxWidth: 400,
                    width: '100%',
                    backgroundColor: '#fff',
                    padding: 4,
                    borderRadius: 2,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
                    Forgot Password
                </Typography>
                <Typography variant="body1" sx={{ color: 'gray', mb: 3 }}>
                    Enter your email address and we’ll send you a link to reset your password.
                </Typography>

                {/* Alert Messages */}
                {successMessage && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        {successMessage}
                    </Alert>
                )}
                {errorMessage && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {errorMessage}
                    </Alert>
                )}

                {/* Email Input Field */}
                <TextField
                    fullWidth
                    label="Email Address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    sx={{ mb: 2 }}
                />

                {/* Submit Button */}
                <Button
                    variant="contained"
                    fullWidth
                    disabled={isLoading} // Nonaktifkan tombol saat loading
                    sx={{
                        backgroundColor: isLoading ? '#007bff80' : '#007bff', // Ubah warna saat loading
                        '&:hover': { backgroundColor: isLoading ? '#007bff80' : '#0056b3' },
                        mb: 2,
                    }}
                    onClick={handleSubmit}
                    startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null} // Tambahkan animasi loading
                >
                    {isLoading ? 'Sending...' : 'Send Reset Link'}
                </Button>

                {/* Back to Login & Register */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 2,
                    }}
                >
                    <Button
                        variant="text"
                        onClick={() => navigate('/login')}
                        sx={{ color: '#007bff', textTransform: 'none' }}
                    >
                        Back to Login
                    </Button>
                    <Button
                        variant="text"
                        onClick={() => navigate('/register')}
                        sx={{ color: '#007bff', textTransform: 'none' }}
                    >
                        Register
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default ForgotPassword;
