import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
            <Typography variant="h4" gutterBottom>
                404 - Page Not Found
            </Typography>
            <Typography variant="body1" gutterBottom>
                Sorry, the page you are looking for does not exist.
            </Typography>
            <Button 
                variant="contained" 
                color="primary" 
                component={Link} 
                to="/"
                sx={{ mt: 2 }}
            >
                Go to Home
            </Button>
        </Box>
    );
};

export default NotFound;
