import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';


const apiUrl = process.env.REACT_APP_API_URL; // Gunakan variabel lingkungan

const AdminIndexFeeSales = () => {
    const [packages, setPackages] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentPackage, setCurrentPackage] = useState({ id: null, packageName: '', feeSales: '' });

    const fetchPackages = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`${apiUrl}/admin/packages`, {
                headers: {
                    Authorization: token,
                },
            });
            setPackages(res.data);
        } catch (err) {
            console.error('Error fetching packages:', err);
        }
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    const handleDialogOpen = (pkg = { id: null, packageName: '', feeSales: '' }) => {
        setCurrentPackage(pkg);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setCurrentPackage({ id: null, packageName: '', feeSales: '' });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentPackage({ ...currentPackage, [name]: value });
    };

    const handleSavePackage = async () => {
        const token = localStorage.getItem('token');
        const { id, packageName, feeSales } = currentPackage;

        try {
            if (id) {
                // Update existing package
                await axios.put(`${apiUrl}/admin/package/${id}`, { packageName, feeSales }, {
                    headers: { Authorization: token },
                });
            } else {
                // Add new package
                await axios.post(`${apiUrl}/admin/package`, { packageName, feeSales }, {
                    headers: { Authorization: token },
                });
            }
            fetchPackages();
            handleDialogClose();
        } catch (err) {
            console.error('Error saving package:', err);
        }
    };

    const handleDeletePackage = async (id) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${apiUrl}/admin/package/${id}`, {
                headers: { Authorization: token },
            });
            fetchPackages();
        } catch (err) {
            console.error('Error deleting package:', err);
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Index Fee Sales
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleDialogOpen()}
                sx={{ mb: 2 }}
            >
                Add New Package
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Package Name</TableCell>
                            <TableCell>Fee Sales (Rp)</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {packages.map((pkg) => (
                            <TableRow key={pkg.id}>
                                <TableCell>{pkg.package_name}</TableCell>
                                <TableCell>{pkg.fee_sales}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleDialogOpen(pkg)}
                                        sx={{ mr: 1 }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => handleDeletePackage(pkg.id)}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dialog for Adding/Editing Package */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>{currentPackage.id ? 'Edit Package' : 'Add Package'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Package Name"
                        name="packageName"
                        fullWidth
                        variant="outlined"
                        value={currentPackage.packageName}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        label="Fee Sales (Rp)"
                        name="feeSales"
                        fullWidth
                        variant="outlined"
                        type="number"
                        value={currentPackage.feeSales}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSavePackage} color="secondary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdminIndexFeeSales;
