import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Typography,
    Button,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Alert,
    useMediaQuery
} from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const Points = () => {
    const [points, setPoints] = useState(0);
    const [pendingRedeem, setPendingRedeem] = useState(0);
    const [history, setHistory] = useState([]);
    const [redeemData, setRedeemData] = useState({ name: '', bank: '', accountNumber: '', pointsToRedeem: '' });
    const [redeemDialogOpen, setRedeemDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [detailDialogOpen, setDetailDialogOpen] = useState(false);
    const [redeemDetail, setRedeemDetail] = useState(null);
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const fetchPoints = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`${apiUrl}/points`, {
                headers: {
                    Authorization: token,
                },
            });
            setPoints(res.data.points);
            setPendingRedeem(res.data.pendingRedeem || 0);
            setHistory(res.data.history);
        } catch (err) {
            console.error('Error fetching points:', err);
        }
    };

    useEffect(() => {
        fetchPoints();
    }, []);

    const handleRedeemChange = (e) => {
        const { name, value } = e.target;
        setRedeemData({ ...redeemData, [name]: value });
    };

    const handleRedeemSubmit = async () => {
        const pointsToRedeem = parseInt(redeemData.pointsToRedeem, 10);
        const availablePoints = points - pendingRedeem;

        if (isNaN(pointsToRedeem) || pointsToRedeem <= 0) {
            setErrorMessage('Invalid points to redeem');
            return;
        }

        if (availablePoints < pointsToRedeem) {
            setErrorMessage('Insufficient points for redeem request');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            await axios.post(`${apiUrl}/redeem`, redeemData, {
                headers: {
                    Authorization: token,
                },
            });
            setRedeemDialogOpen(false);
            setErrorMessage('');
            fetchPoints();
        } catch (err) {
            console.error('Error requesting redeem:', err);
            setErrorMessage('Error submitting redeem request');
        }
    };

    const handleViewDetail = (redeem) => {
        setRedeemDetail(redeem);
        setDetailDialogOpen(true);
    };

    const closeDetailDialog = () => {
        setDetailDialogOpen(false);
        setRedeemDetail(null);
    };

    return (
        <Box sx={{ p: isSmallScreen ? 1 : 3 }}>
            <Typography variant="h4" gutterBottom sx={{ fontSize: isSmallScreen ? '1.5rem' : '2rem' }}>
                Your Points
            </Typography>
            <Card sx={{ mb: 4, padding: isSmallScreen ? 1 : 2 }}>
                <CardContent>
                    <Typography variant="h6" sx={{ fontSize: isSmallScreen ? '1rem' : '1.25rem' }}>
                        Total Points: {points}
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: isSmallScreen ? '1rem' : '1.25rem' }}>
                        Pending Redeem: {pendingRedeem}
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: isSmallScreen ? '1rem' : '1.25rem' }}>
                        Available Points: {points - pendingRedeem}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setRedeemDialogOpen(true)}
                        sx={{
                            mt: 2,
                            fontSize: { xs: '0.8rem', sm: '1rem' }
                        }}
                    >
                        Request Redeem
                    </Button>
                </CardContent>
            </Card>

            {errorMessage && (
                <Alert severity="error" sx={{ mb: 2, fontSize: isSmallScreen ? '0.8rem' : 'inherit' }}>
                    {errorMessage}
                </Alert>
            )}

            <Typography variant="h5" gutterBottom sx={{ fontSize: isSmallScreen ? '1.25rem' : '1.5rem' }}>
                Points History
            </Typography>
            <TableContainer component={Paper}>
                <Table size={isSmallScreen ? 'small' : 'medium'}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontSize: { xs: '0.7rem', sm: 'inherit' } }}>Action</TableCell>
                            <TableCell sx={{ fontSize: { xs: '0.7rem', sm: 'inherit' } }}>Points</TableCell>
                            <TableCell sx={{ fontSize: { xs: '0.7rem', sm: 'inherit' } }}>Status</TableCell>
                            <TableCell sx={{ fontSize: { xs: '0.7rem', sm: 'inherit' } }}>Date</TableCell>
                            <TableCell sx={{ fontSize: { xs: '0.7rem', sm: 'inherit' } }}>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.map((entry) => (
                            <TableRow key={entry.id}>
                                <TableCell sx={{ fontSize: { xs: '0.7rem', sm: 'inherit' } }}>{entry.action}</TableCell>
                                <TableCell sx={{ fontSize: { xs: '0.7rem', sm: 'inherit' } }}>{entry.points}</TableCell>
                                <TableCell sx={{ fontSize: { xs: '0.7rem', sm: 'inherit' } }}>{entry.status}</TableCell>
                                <TableCell sx={{ fontSize: { xs: '0.7rem', sm: 'inherit' } }}>{new Date(entry.created_at).toLocaleString()}</TableCell>
                                <TableCell>
                                    {entry.action === 'redeem' && (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleViewDetail(entry)}
                                            sx={{
                                                fontSize: { xs: '0.7rem', sm: 'inherit' }
                                            }}
                                        >
                                            Lihat Detail
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={redeemDialogOpen} onClose={() => setRedeemDialogOpen(false)}>
                <DialogTitle sx={{ fontSize: isSmallScreen ? '1.2rem' : '1.5rem' }}>Request Redeem</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        name="name"
                        fullWidth
                        variant="outlined"
                        value={redeemData.name}
                        onChange={handleRedeemChange}
                        sx={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }}
                    />
                    <TextField
                        margin="dense"
                        label="Bank"
                        name="bank"
                        fullWidth
                        variant="outlined"
                        value={redeemData.bank}
                        onChange={handleRedeemChange}
                        sx={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }}
                    />
                    <TextField
                        margin="dense"
                        label="Account Number"
                        name="accountNumber"
                        fullWidth
                        variant="outlined"
                        value={redeemData.accountNumber}
                        onChange={handleRedeemChange}
                        sx={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }}
                    />
                    <TextField
                        margin="dense"
                        label="Points to Redeem"
                        name="pointsToRedeem"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={redeemData.pointsToRedeem}
                        onChange={handleRedeemChange}
                        sx={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRedeemDialogOpen(false)} color="primary" sx={{ fontSize: { xs: '0.8rem', sm: 'inherit' } }}>
                        Cancel
                    </Button>
                    <Button onClick={handleRedeemSubmit} color="secondary" sx={{ fontSize: { xs: '0.8rem', sm: 'inherit' } }}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={detailDialogOpen} onClose={closeDetailDialog}>
                <DialogTitle sx={{ fontSize: isSmallScreen ? '1.2rem' : '1.5rem' }}>Detail Redeem</DialogTitle>
                <DialogContent>
                    {redeemDetail && (
                        <>
                            <DialogContentText sx={{ fontSize: isSmallScreen ? '0.8rem' : 'inherit' }}>
                                Nama: {redeemDetail.name}
                            </DialogContentText>
                            <DialogContentText sx={{ fontSize: isSmallScreen ? '0.8rem' : 'inherit' }}>
                                Bank: {redeemDetail.bank}
                            </DialogContentText>
                            <DialogContentText sx={{ fontSize: isSmallScreen ? '0.8rem' : 'inherit' }}>
                                No Rekening: {redeemDetail.account_number}
                            </DialogContentText>
                            {redeemDetail.transfer_proof && (
                                <DialogContentText sx={{ fontSize: isSmallScreen ? '0.8rem' : 'inherit' }}>
                                    Bukti Transfer: <a href={`${apiUrl}/uploads/${redeemDetail.transfer_proof}`} target="_blank" rel="noopener noreferrer">Lihat Bukti Transfer</a>
                                </DialogContentText>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDetailDialog} color="primary" sx={{ fontSize: { xs: '0.8rem', sm: 'inherit' } }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Points;
