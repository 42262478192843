import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
    Box, Typography, Button, TextField, MenuItem, 
    FormControl, InputLabel, Select, Paper, Table, 
    TableBody, TableCell, TableContainer, TableHead, 
    TableRow, Pagination, InputAdornment, Alert, Dialog, 
    DialogTitle, DialogContent, DialogContentText, 
    DialogActions, List, ListItem, useMediaQuery 
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const apiUrl = process.env.REACT_APP_API_URL;

const Order = () => {
    const [formData, setFormData] = useState({
        pic: '',
        businessName: '',
        ktpNumber: '',
        birthPlace: '',
        birthPlaceDate: '',
        email: '',
        phoneNumber: '',
        address: '',
        packageId: '',
        nibFile: null,
        locationFile: null,
        ktpFile: null,
        selfieKtpFile: null
    });
    
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({ ...formData, [name]: files[0] });
    };
    const [packages, setPackages] = useState([]);
    const [orders, setOrders] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [search, setSearch] = useState('');
    const [errors, setErrors] = useState([]);
    const [submissionMessage, setSubmissionMessage] = useState('');
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null); 
    const isSmallScreen = useMediaQuery('(max-width:600px)'); 
    const [paperlessLink, setPaperlessLink] = useState(null); // For storing the paperless link
  

    const handleCheckPaperlessLink = async (scid) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${apiUrl}/get-paperless-link/${scid}`, {
                headers: { Authorization: token }
            });

            if (response.data.link) {
                setPaperlessLink(response.data.link); // Set the link if found
            } else {
                setPaperlessLink('No paperless link available'); // Set message if not found
            }
        } catch (error) {
            console.error('Error fetching paperless link:', error);
            setPaperlessLink('Error fetching paperless link');
        }
    };

    const fetchPackages = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`${apiUrl}/packages`, {
                headers: { Authorization: token },
            });
            setPackages(res.data);
        } catch (err) {
            console.error('Error fetching packages:', err);
        }
    };

    const fetchOrders = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`${apiUrl}/orders`, {
                headers: { Authorization: token },
                params: { page, limit, search },
            });
            setOrders(res.data.data);
            setTotalOrders(res.data.total);
        } catch (err) {
            console.error('Error fetching orders:', err);
        }
    };

    const handleCheckStatus = async (scid) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${apiUrl}/update-order-status/${scid}`, {}, {
                headers: { Authorization: token }
            });
            fetchOrders(); 
            setSubmissionMessage(`Status updated for SCID: ${scid}`);
        } catch (error) {
            console.error('Error checking order status:', error);
            setSubmissionMessage('Error checking order status');
        }
    };

    useEffect(() => {
        fetchPackages();
        fetchOrders();
    }, [page, search]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors([]);
    };

    const handleSubmit = async () => {
        const formDataToSend = new FormData();
        
        // Append form fields
        formDataToSend.append('pic', formData.pic);
        formDataToSend.append('businessName', formData.businessName);
        formDataToSend.append('ktpNumber', formData.ktpNumber);
        formDataToSend.append('birthPlace', formData.birthPlace);
        formDataToSend.append('birthPlaceDate', formData.birthPlaceDate);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phoneNumber', formData.phoneNumber);
        formDataToSend.append('address', formData.address);
        formDataToSend.append('packageId', formData.packageId);
    
        // Append files if they exist
        if (formData.nibFile) formDataToSend.append('nibFile', formData.nibFile);
        if (formData.locationFile) formDataToSend.append('locationFile', formData.locationFile);
        if (formData.ktpFile) formDataToSend.append('ktpFile', formData.ktpFile);
        if (formData.selfieKtpFile) formDataToSend.append('selfieKtpFile', formData.selfieKtpFile);
    
        try {
            const token = localStorage.getItem('token');
            await axios.post(`${apiUrl}/order`, formDataToSend, {
                headers: {
                    Authorization: token,
                    'Content-Type': 'multipart/form-data'  // Important for file uploads
                },
            });
    
            // Reset form data after successful submission
            setFormData({
                pic: '',
                businessName: '',
                ktpNumber: '',
                birthPlace: '',
                birthPlaceDate: '',
                email: '',
                phoneNumber: '',
                address: '',
                packageId: '',
                nibFile: null,
                locationFile: null,
                ktpFile: null,
                selfieKtpFile: null
            });
            setErrors([]);
            setSubmissionMessage('Order submitted successfully');
            setConfirmOpen(false);
            setIsFormVisible(false);
            fetchOrders();
        } catch (err) {
            if (err.response && err.response.data.errors) {
                setErrors(err.response.data.errors);
                setSubmissionMessage('');
            } else {
                setSubmissionMessage('Error submitting order');
            }
        }
    };
    

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleConfirmOpen = () => {
        setConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
        setSubmissionMessage('');
        setErrors([]);
    };

    const handleRowClick = (order) => {
        console.log('Selected Order:', order); // Debugging
        setSelectedOrder(order);
        setPaperlessLink(null); // Reset link on close
    };

    const handleCloseModal = () => {
        setSelectedOrder(null);
        setPaperlessLink(null); // Reset link on close
    };

    return (
        <Box sx={{ p: isSmallScreen ? 1 : 3 }}>
            
            <Button 
                variant="contained" 
                onClick={toggleFormVisibility} 
                sx={{ mb: 3, backgroundColor: '#000', color: '#fff', fontSize: isSmallScreen ? '12px' : 'inherit' }}
            >
                {isFormVisible ? 'Tutup Leads Form' : 'Tambah Leads Baru'}
            </Button>
            {isFormVisible && (
                <Paper sx={{ p: isSmallScreen ? 1 : 3, mb: 4 }}>
                    <form onSubmit={(e) => { e.preventDefault(); handleConfirmOpen(); }}>
                        {submissionMessage && (
                            <Alert severity={submissionMessage.includes('successfully') ? 'success' : 'error'} sx={{ mb: 2 }}>
                                {submissionMessage}
                            </Alert>
                        )}
                        {errors.length > 0 && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                <List>
                                    {errors.map((error, index) => (
                                        <ListItem key={index}>{error.message}</ListItem>
                                    ))}
                                </List>
                            </Alert>
                        )}
                        <TextField
                            label="Nama Pelanggan"
                            name="pic"
                            value={formData.pic}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Nama Bisnis"
                            name="businessName"
                            value={formData.businessName}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Nomer KTP"
                            name="ktpNumber"
                            value={formData.ktpNumber}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Tempat Lahir"
                            name="birthPlace"
                            value={formData.birthPlace}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Tanggal Lahir"
                            name="birthPlaceDate"
                            type="date"
                            value={formData.birthPlaceDate}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            required
                        />
                        <TextField
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Nomer HP"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Alamat"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <FormControl fullWidth margin="normal" required>
                            <InputLabel id="package-label">Paket</InputLabel>
                            <Select
                                labelId="package-label"
                                name="packageId"
                                value={formData.packageId}
                                onChange={handleChange}
                                label="Package"
                            >
                                {packages.map((pkg) => (
                                    <MenuItem key={pkg.id} value={pkg.id}>
                                        {pkg.package_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                    label="Foto NIB/NPWP (Opsional)"
                    name="nibFile"
                    type="file"
                    onChange={handleFileChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Foto Lokasi Tampak Depan (Opsional)"
                    name="locationFile"
                    type="file"
                    onChange={handleFileChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Foto KTP (Opsional)"
                    name="ktpFile"
                    type="file"
                    onChange={handleFileChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Foto Selfie dengan KTP (Opsional)"
                    name="selfieKtpFile"
                    type="file"
                    onChange={handleFileChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                />

                        <Button 
                            type="submit" 
                            variant="contained" 
                            sx={{ mt: 2, backgroundColor: '#000', color: '#fff', fontSize: isSmallScreen ? '12px' : 'inherit' }}
                        >
                            Submit
                        </Button>
                    </form>
                </Paper>
            )}

            <Dialog open={confirmOpen} onClose={handleConfirmClose}>
                <DialogTitle>Confirm Leads Submission</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to submit the following order details?</DialogContentText>
                    <List>
                        <ListItem>PIC: {formData.pic}</ListItem>
                        <ListItem>Business Name: {formData.businessName}</ListItem>
                        <ListItem>KTP Number: {formData.ktpNumber}</ListItem>
                        <ListItem>Tempat Lahir: {formData.birthPlace}</ListItem>
                        <ListItem>Tanggal Lahir: {formData.birthPlaceDate}</ListItem>
                        <ListItem>Email: {formData.email}</ListItem>
                        <ListItem>Phone Number: {formData.phoneNumber}</ListItem>
                        <ListItem>Address: {formData.address}</ListItem>
                        <ListItem>Package: {packages.find((pkg) => pkg.id === formData.packageId)?.package_name || 'N/A'}</ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmClose} sx={{ color: '#000' }}>Cancel</Button>
                    <Button onClick={handleSubmit} sx={{ color: '#000' }}>Confirm</Button>
                </DialogActions>
            </Dialog>

           
            <TextField
                placeholder="Search leads..."
                variant="outlined"
                fullWidth
                margin="normal"
                value={search}
                onChange={handleSearchChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                sx={{ fontSize: isSmallScreen ? '12px' : 'inherit' }}
            />

            {submissionMessage && (
                <Alert severity="info" sx={{ mt: 2, mb: 2 }}>{submissionMessage}</Alert>
            )}

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Business Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((order) => (
                            <TableRow 
                                key={order.id} 
                                onClick={() => handleRowClick(order)} 
                                style={{ cursor: 'pointer' }}
                            >
                                <TableCell>{order.business_name}</TableCell>
                                <TableCell>{order.status}</TableCell>
                                <TableCell>
                                    {order.scid ? (
                                        <Button 
                                            variant="outlined" 
                                            onClick={(e) => { e.stopPropagation(); handleCheckStatus(order.scid); }}
                                            sx={{ fontSize: isSmallScreen ? '10px' : 'inherit' }}
                                        >
                                            Check Status
                                        </Button>
                                    ) : (
                                        "Belum SC"
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={Math.ceil(totalOrders / limit)}
                page={page}
                onChange={handlePageChange}
                sx={{ mt: 2 }}
            />

            {selectedOrder && (
                <Dialog open={Boolean(selectedOrder)} onClose={handleCloseModal}>
                <DialogTitle>Leads Details</DialogTitle>
                <DialogContent>
                    <List>
                        <ListItem>Business Name: {selectedOrder.business_name}</ListItem>
                        <ListItem>Status: {selectedOrder.status}</ListItem>
                        <ListItem>Keterangan: {selectedOrder.keterangan || 'N/A'}</ListItem>
                        <ListItem>SCID: {selectedOrder.scid || 'N/A'}</ListItem>
                        <ListItem>Track ID: {selectedOrder.trackid || 'N/A'}</ListItem>
                        <ListItem>Email: {selectedOrder.email}</ListItem>
                        <ListItem>Phone Number: {selectedOrder.phone_number}</ListItem>
                        <ListItem>Address: {selectedOrder.address}</ListItem>
                        <ListItem>Package: {selectedOrder.package_name || 'N/A'}</ListItem>
                        {/* Display file links if available */}
                        {selectedOrder.nib_file && (
    <ListItem>
        NIB File: 
        <a href={`${apiUrl}/uploads/${selectedOrder.nib_file}`} target="_blank" rel="noopener noreferrer">
            {selectedOrder.nib_file}
        </a>
    </ListItem>
)}
{selectedOrder.location_file && (
    <ListItem>
        Location File: 
        <a href={`${apiUrl}/uploads/${selectedOrder.location_file}`} target="_blank" rel="noopener noreferrer">
            {selectedOrder.location_file}
        </a>
    </ListItem>
)}
{selectedOrder.ktp_file && (
    <ListItem>
        KTP File: 
        <a href={`${apiUrl}/uploads/${selectedOrder.ktp_file}`} target="_blank" rel="noopener noreferrer">
            {selectedOrder.ktp_file}
        </a>
    </ListItem>
)}
{selectedOrder.selfie_ktp_file && (
    <ListItem>
        Selfie with KTP: 
        <a href={`${apiUrl}/uploads/${selectedOrder.selfie_ktp_file}`} target="_blank" rel="noopener noreferrer">
            {selectedOrder.selfie_ktp_file}
        </a>
    </ListItem>
)}
 {selectedOrder.scid && (
                            <ListItem>
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    onClick={() => handleCheckPaperlessLink(selectedOrder.scid)}
                                    sx={{ mt: 2 }}
                                >
                                    Check Link Paperless
                                </Button>
                            </ListItem>
                        )}
                        {paperlessLink && (
                            <ListItem>
                                Paperless Link: 
                                {paperlessLink.startsWith('http') ? (
                                    <a href={paperlessLink} target="_blank" rel="noopener noreferrer">
                                        {paperlessLink}
                                    </a>
                                ) : (
                                    <span>{paperlessLink}</span>
                                )}
                            </ListItem>
                        )}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} sx={{ color: '#000' }}>Close</Button>
                </DialogActions>
            </Dialog>
            )}
        </Box>
    );
};

export default Order;
