import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Card, CardContent, Grid, InputLabel, FormControl, Box, Typography, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper,
    Button, TextField, TablePagination, Select, MenuItem,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Alert, IconButton, Menu, MenuItem as DropdownItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const apiUrl = process.env.REACT_APP_API_URL;

const Admin = () => {
    const [orders, setOrders] = useState([]);
    const [packages, setPackages] = useState([]);
    const [editingOrder, setEditingOrder] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showDetailDialog, setShowDetailDialog] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [deleteOrderId, setDeleteOrderId] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [paperlessLink, setPaperlessLink] = useState('');
    const [isPaperlessModalOpen, setIsPaperlessModalOpen] = useState(false);
    const [filterMonth, setFilterMonth] = useState('');
    const [filterYear, setFilterYear] = useState('');
    const [filterStatus, setFilterStatus] = useState(''); // New filter for order status
    const [statusSummary, setStatusSummary] = useState({});

    const handleCheckPaperless = async (scid) => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`${apiUrl}/get-paperless-link/${scid}`, {
                headers: { Authorization: `${token}` },
            });
            setPaperlessLink(res.data.link || 'No paperless link available');
            setIsPaperlessModalOpen(true);
        } catch (error) {
            console.error('Error fetching paperless link:', error);
            setPaperlessLink('Error fetching paperless link');
            setIsPaperlessModalOpen(true);
        }
    };

    const handleCheckStatus = async (scid) => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.put(`${apiUrl}/update-order-status/${scid}`, {}, {
                headers: { Authorization: `${token}` },
            });
            setStatusMessage(`Status: ${res.data.status}, Keterangan: ${res.data.keterangan}`);
            fetchOrders();
        } catch (error) {
            console.error('Error checking order status:', error);
            setStatusMessage('Error checking order status');
        }
    };

    const fetchOrders = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`${apiUrl}/admin/orders`, {
                headers: { Authorization: `${token}` },
                params: {
                    month: filterMonth || null,
                    year: filterYear || null,
                    status: filterStatus || null, // Kirim filter status
                },
            });
            const ordersData = res.data;
            setOrders(ordersData);
    
            // Buat ringkasan status
            const summary = ordersData.reduce((acc, order) => {
                acc[order.status] = (acc[order.status] || 0) + 1;
                return acc;
            }, {});
            setStatusSummary(summary);
        } catch (err) {
            console.error('Error fetching orders:', err);
            setErrorMessage('An error occurred while fetching orders.');
        }
    };
    
  
    const fetchPackages = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`${apiUrl}/admin/packages`, {
                headers: { Authorization: `${token}` },
            });
            setPackages(res.data);
        } catch (err) {
            console.error('Error fetching packages:', err);
            setErrorMessage('An error occurred while fetching packages.');
        }
    };

    useEffect(() => {
        fetchOrders();
        fetchPackages();
    }, [filterMonth, filterYear, filterStatus]); // Re-fetch orders when filters change

    const handleMenuOpen = (event, id) => {
        setAnchorEl({ anchor: event.currentTarget, orderId: id });
    };

    const handleMenuClose = () => setAnchorEl(null);

    const openDeleteDialog = (id) => {
        setDeleteOrderId(id);
        setIsDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => setIsDeleteDialogOpen(false);

    const handleDelete = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${apiUrl}/admin/order/${deleteOrderId}`, {
                headers: { Authorization: `${token}` },
            });
            fetchOrders();
            closeDeleteDialog();
        } catch (err) {
            console.error('Error deleting order:', err);
            setErrorMessage('An error occurred while deleting the order.');
        }
    };

    const handleEdit = (order) => setEditingOrder({ ...order });

    const handleSave = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${apiUrl}/admin/order/${editingOrder.id}`, {
                trackid: editingOrder.trackid,
                scid: editingOrder.scid,
                status: editingOrder.status,
            }, {
                headers: { Authorization: `${token}` },
            });
            fetchOrders();
            setEditingOrder(null);
        } catch (err) {
            console.error('Error updating order:', err);
            setErrorMessage('An error occurred while updating the order.');
        }
    };

    const handleCancelEdit = () => setEditingOrder(null);

    const handleShowDetails = (order) => {
        setSelectedOrder(order);
        setShowDetailDialog(true);
    };

    const closeShowDetailDialog = () => setShowDetailDialog(false);

    const filteredOrders = orders.filter((order) =>
        order.business_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.pic.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.user_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const paginatedOrders = filteredOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const getPackageName = (package_id) => {
        const packageItem = packages.find((pkg) => pkg.id === package_id);
        return packageItem ? packageItem.package_name : '-';
    };

    return (
        <Box sx={{ width: '90%', padding: 2, overflowX: 'auto' }}>
     

        {/* Dynamic Status Summary Table */}
        <TableContainer component={Paper} sx={{ mb: 4 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Status</strong></TableCell>
                            <TableCell><strong>Jumlah</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(statusSummary).map(([status, count]) => (
                            <TableRow key={status}>
                                <TableCell>{status}</TableCell>
                                <TableCell>{count}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell><strong>Total</strong></TableCell>
                            <TableCell><strong>{orders.length}</strong></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid container spacing={2} sx={{ mb: 2 }}>
    <Grid item xs={4}>
        <FormControl fullWidth>
            <InputLabel>Month</InputLabel>
            <Select
                value={filterMonth}
                onChange={(e) => setFilterMonth(e.target.value)}
                label="Month"
            >
                <MenuItem value="">All</MenuItem> {/* Opsi All */}
                {[...Array(12)].map((_, index) => (
                    <MenuItem key={index} value={index + 1}>
                        {new Date(0, index).toLocaleString('default', { month: 'long' })}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </Grid>
    <Grid item xs={4}>
        <FormControl fullWidth>
            <InputLabel>Year</InputLabel>
            <Select
                value={filterYear}
                onChange={(e) => setFilterYear(e.target.value)}
                label="Year"
            >
                <MenuItem value="">All</MenuItem> {/* Opsi All */}
                {[2021, 2022, 2023, 2024].map((year) => (
                    <MenuItem key={year} value={year}>
                        {year}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </Grid>
    <Grid item xs={4}>
        <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                label="Status"
            >
                <MenuItem value="">All</MenuItem> {/* Opsi All */}
                {Object.keys(statusSummary).map((status) => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                ))}
            </Select>
        </FormControl>
    </Grid>
</Grid>

        {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
        {statusMessage && <Alert severity="info" sx={{ mb: 2 }}>{statusMessage}</Alert>}

        <TextField
            label="Search Orders"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
        />

            <TableContainer component={Paper}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>User Name</TableCell>
                            <TableCell>Business Name</TableCell>
                            <TableCell>SCID</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedOrders.map((order) => (
                            <TableRow key={order.id}>
                                <TableCell>{order.user_name}</TableCell>
                                <TableCell>{order.business_name}</TableCell>
                                <TableCell>
                                    {editingOrder?.id === order.id ? (
                                        <TextField
                                            value={editingOrder.scid || ''}
                                            onChange={(e) => setEditingOrder({ ...editingOrder, scid: e.target.value })}
                                            fullWidth
                                            size="small"
                                        />
                                    ) : (
                                        order.scid || '-'
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editingOrder?.id === order.id ? (
                                        <Select
                                            value={editingOrder.status}
                                            onChange={(e) => setEditingOrder({ ...editingOrder, status: e.target.value })}
                                            fullWidth
                                            size="small"
                                        >
                                            <MenuItem value="Registered">Registered</MenuItem>
                                            <MenuItem value="Inprogress">Inprogress</MenuItem>
                                            <MenuItem value="Completed">Completed</MenuItem>
                                            <MenuItem value="Cancel">Cancel</MenuItem>
                                        </Select>
                                    ) : (
                                        order.status
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editingOrder?.id === order.id ? (
                                        <>
                                            <Button onClick={handleSave} color="primary" variant="contained" size="small" sx={{ mr: 1 }}>Save</Button>
                                            <Button onClick={handleCancelEdit} color="secondary" variant="outlined" size="small">Cancel</Button>
                                        </>
                                    ) : (
                                        <>
                                            <IconButton onClick={(e) => handleMenuOpen(e, order.id)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl?.anchor}
                                                open={anchorEl?.orderId === order.id}
                                                onClose={handleMenuClose}
                                            >
                                                <DropdownItem onClick={() =>  handleShowDetails(order)}>Show Detail</DropdownItem>
                                                <DropdownItem onClick={() => handleEdit(order)}>Edit</DropdownItem>
                                                <DropdownItem onClick={() => openDeleteDialog(order.id)}>Delete</DropdownItem>
                                                <DropdownItem onClick={() => handleCheckStatus(order.scid)}>Check Status</DropdownItem>
                                                <DropdownItem onClick={() => handleCheckPaperless(order.scid)}>Check Paperless</DropdownItem>
                                            </Menu>
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={filteredOrders.length}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
                rowsPerPageOptions={[5, 10, 25]}
                sx={{ mt: 2 }}
            />

            <Dialog open={showDetailDialog} onClose={closeShowDetailDialog}>
                <DialogTitle>Order Details</DialogTitle>
                <DialogContent>
                    {selectedOrder && (
                        <Box>
                           
                            <Typography variant="body1"><strong>User Name:</strong> {selectedOrder.user_name}</Typography>
                            <Typography variant="body1"><strong>PIC:</strong> {selectedOrder.pic}</Typography>
                            <Typography variant="body1"><strong>Business Name:</strong> {selectedOrder.business_name}</Typography>
                            <Typography variant="body1"><strong>KTP Number:</strong> {selectedOrder.ktp_number}</Typography>
                            <Typography variant="body1"><strong>Birth Place:</strong> {selectedOrder.birth_place}</Typography>
                            <Typography variant="body1"><strong>Birth Date:</strong> {selectedOrder.birth_place_date}</Typography>
                            <Typography variant="body1"><strong>Email:</strong> {selectedOrder.email}</Typography>
                            <Typography variant="body1"><strong>Phone Number:</strong> {selectedOrder.phone_number}</Typography>
                            <Typography variant="body1"><strong>Address:</strong> {selectedOrder.address}</Typography>
                            <Typography variant="body1"><strong>Package:</strong> {getPackageName(selectedOrder.package_id)}</Typography>
                            <Typography variant="body1"><strong>Track ID:</strong> {selectedOrder.trackid || '-'}</Typography>
                            <Typography variant="body1"><strong>SCID:</strong> {selectedOrder.scid || '-'}</Typography>
                            <Typography variant="body1"><strong>Status:</strong> {selectedOrder.status}</Typography>
                            <Typography variant="body1"><strong>Files:</strong></Typography>
                            {selectedOrder.nib_file && <Typography variant="body2"><a href={`${apiUrl}/uploads/${selectedOrder.nib_file}`} target="_blank" rel="noopener noreferrer">NIB File</a></Typography>}
                            {selectedOrder.location_file && <Typography variant="body2"><a href={`${apiUrl}/uploads/${selectedOrder.location_file}`} target="_blank" rel="noopener noreferrer">Location File</a></Typography>}
                            {selectedOrder.ktp_file && <Typography variant="body2"><a href={`${apiUrl}/uploads/${selectedOrder.ktp_file}`} target="_blank" rel="noopener noreferrer">KTP File</a></Typography>}
                            {selectedOrder.selfie_ktp_file && <Typography variant="body2"><a href={`${apiUrl}/uploads/${selectedOrder.selfie_ktp_file}`} target="_blank" rel="noopener noreferrer">Selfie KTP File</a></Typography>}
                        
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeShowDetailDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this order? This action cannot be undone.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">Cancel</Button>
                    <Button onClick={handleDelete} sx={{ backgroundColor: "black", color: "white" }}>Delete</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isPaperlessModalOpen} onClose={() => setIsPaperlessModalOpen(false)}>
                <DialogTitle>Paperless Link</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {paperlessLink.startsWith('http') ? (
                            <a href={paperlessLink} target="_blank" rel="noopener noreferrer">
                                Click here to view the paperless document
                            </a>
                        ) : (
                            paperlessLink
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPaperlessModalOpen(false)} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Admin;
