// Brosur.js
import React from 'react';
import { Box, Typography, Grid, Paper, Button } from '@mui/material';

// Import images
import Brochure1 from './1.jpeg';
import Brochure2 from './2.jpeg';
import Brochure3 from './3.jpeg';
import Brochure4 from './4.jpeg';

const brochureList = [
    { title: 'Brochure 1', filePath: Brochure1 },
    { title: 'Brochure 2', filePath: Brochure2 },
    { title: 'Brochure 3', filePath: Brochure3 },
    { title: 'Brochure 4', filePath: Brochure4 },
];

const Brosur = () => {
    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Brosur
            </Typography>
            <Grid container spacing={2}>
                {brochureList.map((brochure, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Paper sx={{ p: 2, textAlign: 'center' }}>
                            <Typography variant="h6">{brochure.title}</Typography>
                            <Box
                                component="img"
                                src={brochure.filePath}
                                alt={brochure.title}
                                sx={{ width: '100%', borderRadius: '8px', mt: 1 }}
                            />
                            <Button
                                variant="contained"
                                sx={{ mt: 2 }}
                                href={brochure.filePath}
                                download
                            >
                                Download
                            </Button>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Brosur;
